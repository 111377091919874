<template>
	<v-row>
		<completion-rate :height="20" :value="completion"></completion-rate>
		<v-col>
			<number-tile :number="count('users')" text="Colleagues"></number-tile>
			<number-tile :number="count('sections')" text="Sections"></number-tile>
			<number-tile :number="count('evidence')" text="Evidence documents"></number-tile>
		</v-col>
	</v-row>
</template>
<script>
import { listFiles } from "@/assets/storageFunctions.js";
import NumberTile from "@c/ui/NumberTile.vue";
import CompletionRate from "@c/navigation/assessment/CompletionRate.vue";

export default {
	name: "OrganisationStatistics",
	computed: {
		organisation() {
			return this.$store.state.organisation.data;
		},
		users() {
			return this.$store.state.users.data;
		},
		sections() {
			return this.$store.getters.order;
		},
		completion() {
			return this.$store.getters.completionRate;
		},
	},
	data: () => {
		return {
			evidence: [],
		};
	},
	components: { 
		NumberTile,
		CompletionRate
	},
	methods: {
		count(a) {
			let count = 0;
			let values = this[a];
			if (values) {
				if (Array.isArray(values)) {
					count = values.length;
				} else {
					count = Object.keys(values).length;
				}
			}
			return count;
		},
	},
	watch: {
		organisation: {
			immediate: true,
			handler(value) {
				if (value && value.id) {
					const self = this;
					listFiles(`organisations/${value.id}`).then((a) => {
						a.items.forEach((item) => {
							let file = {};
							file.name = item.name;
							file.path = item.fullPath;
							self.evidence.push(file);
						});
					});
				}
			},
		},
	},
};
</script>
