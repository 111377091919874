<template>
    
	<v-list-item class="pt-5">
        <v-list-item-content>
            <v-list-item-title>
                <v-progress-linear :height="`${height}px`" :value="value">
                    <span class="white--text">{{value}}%</span>
                </v-progress-linear>
            </v-list-item-title>
            <v-list-item-subtitle>
                {{$t("assessment.progress")}}
            </v-list-item-subtitle>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
export default {
    name: "CompletionRate", 
    props: {
        height:  {type: Number },
        value: {type: Number },
    },
    data: () => {
        return {
        }
    }
}
</script>